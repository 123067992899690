.currency-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    thead {
      background-color: #f2f2f2;
      tr {
        th {
          padding: 8px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 8px;
          border: 1px solid #ddd;
        }
      }
    }
  }
}

.input-super-conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 12px;
  }
  .input-container {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    select {
      margin: 0;
    }

    .input-label {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      text-shadow: -2px 2px #ddd;
    }
    input {
      width: 100px;
      /* height: 30px; */

      padding: 10px 20px;
      text-align: center;
      font-size: 18px;

      text-transform: uppercase;
    }
  }
}
.inline {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 30px;
}
