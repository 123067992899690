.row {
  display: flex;
}

.table-value-col {
  border: 1px solid $table-border-color;
  min-width: 100px;
}

.table-header-col {
  border: 1px solid $table-border-color;
  min-width: 100px;
  color: $table-font-grey;
  padding: 10px 15px;
  text-align: center;
}
.table-header-row {
  border-radius: 4px;
  overflow: hidden;
  background-color: $table-bg-header;
  /* box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: -5px 5px 5px #eee;
}

.skrito {
  display: none;
}
.table-value-col {
  color: $table-font-grey;
  padding: 10px 15px;
  text-align: right;
}

.table-row-campaign {
  background-color: $table-bg-camp;
  overflow: hidden;
}

.table-row-adset {
  background-color: $table-bg-adset;
}

.table-row-ad {
  background-color: $table-bg-ad;
}

.table-div {
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
}
.table-data {
  border-radius: 4px;
  overflow: hidden;
  /* box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: -5px 5px 5px #eee;
}

.loading-div {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  color: $table-font-blue;
  img {
    max-width: 250px;
    animation: spin-and-slow-down 5s infinite linear;
    margin-bottom: 30px;
  }
}

.campaign {
  width: 305px;
  color: $table-font-blue;
  cursor: pointer;
  overflow: hidden;
  text-align: left;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-and-slow-down {
  0% {
    transform: rotate(0deg);
    scale: 100%;
  }
  25% {
    scale: 60%;
  }
  50% {
    scale: 80%;
    transform: rotate(360deg);
  }
  80% {
    scale: 50%;
  }
  100% {
    transform: rotate(450deg);
    animation-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
    scale: 100%;
  }
}

.col-adset {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  gap: 10px;

  img {
    max-height: 20px;
  }
}
.totals-table {
  max-width: 500px;
  border-collapse: collapse;
  margin: 10px;
  box-shadow: -5px 5px 5px #eee;
}
.totals-row {
  background-color: $table-bg-camp;
  max-width: 500px !important;
}
.totals-value-col {
  border: 1px solid $table-border-color;
  color: $table-font-grey;
  padding: 10px 15px;
  text-align: right;
}
.store {
  color: $table-font-blue;
  min-width: 100px;
  text-align: left;
}
.totals-header-col {
  border: 1px solid $table-border-color;
  color: $table-font-grey;
  padding: 10px 15px;
  text-align: center;
}

.ad-account-switch-container {
  /* box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: -5px 5px 5px #eee;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.ad-account-switch {
  border: 2px solid #3d5468;
  background-color: #193851;
  padding: 10px 15px;
  border-radius: 4px;
  overflow: hidden;
  color: #eee;
  box-shadow: -5px 5px 5px #eee;
  cursor: pointer;
  font-weight: 600;
}
.ad-account-switch-active {
  background-color: #eee;
  color: #193851;
  border-color: #193851;
}

.ad-account-title {
  margin-bottom: 10px;
  font-size: 24px;
}

.ads-table-splitter {
  font-size: 24px;
  margin: 10px 0;
}
